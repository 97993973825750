import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import Layout from '../../components/layout'
import { useStaticQuery, graphql } from "gatsby";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles( theme =>({
    table: {
        minWidth: 650,
        fontFamily:"Montserrat"
    },
    margin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        fontFamily:"Arial Black"
    },
    row: {
        backgroundColor: '#DDD'
    },
    boldText:{
        fontWeight: 'bold',
        fontFamily:"Arial Black"
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        fontFamily:"Arial Black"
        
      },
     tab:{
        textTransform: 'none',
        height: '48px',
        fontFamily:"Arial Black"
    },
    font:{
      fontFamily:"Montserrat"
    }
}));

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
export default function DenseTable() {
  const classes = useStyles();
  const data= useStaticQuery(graphql`
  query {
    allRestApiApiBonusesShowall {
      edges {
        node {
          data {
            bonuses {
              address
              business
              city
              discount
            }
          }
        }
      }
    }
  }`)
    const sortByProperty = function (property) {
    return function (x, y) {
        return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1));
    };
    };
    let bonucesByCity={}
    let compareCity=''
    const cities=[]
    console.log(data.allRestApiApiBonusesShowall.edges[0].node.data.bonuses.sort(sortByProperty('city')))
    data.allRestApiApiBonusesShowall.edges[0].node.data.bonuses.forEach(item => {
        if(compareCity!==item.city){
            bonucesByCity[item.city]=[]
            compareCity=item.city
            cities.push(compareCity)
    }
    bonucesByCity[compareCity].push(item)
    })

    const [value, setValue] = React.useState(0);
    const [actualBonuses, setActualBonuses]= React.useState(bonucesByCity[cities[0]].sort(sortByProperty('business')))
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  //console.log((data.allRestApiApiBonusesShowall.edges[0].node.data.bonuses).filter('city 2'))
  return (
    <Layout>
        <Typography className={classes.margin} variant="h5">Descuentos</Typography>
        <Grid 
        container
        direction="row">
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
          {
              cities.map((city, index)=>{
                  return (
                    <Tab className={classes.tab} label={city} {...a11yProps(index)} onClick={()=>{setActualBonuses(bonucesByCity[city].sort(sortByProperty('business')));}}/>
                  )
              })
          }
      </Tabs>
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
    <TableContainer component={Paper}>
    <Grid container xs={12} 
     direction="column"
     justify="center"
     alignItems="center">
    <Typography className={classes.margin} variant="h6">{actualBonuses[0].city}</Typography>
    </Grid>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.boldText} >Negocio</TableCell>
            <TableCell className={classes.boldText} >Descuento</TableCell>
            <TableCell className={classes.boldText} >Domicilio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actualBonuses.map((row, index)=> {
            
            return (
             <TableRow key={row.name} className={(index%2>0)?classes.row: null}>
              <TableCell component="th" scope="row" className={classes.font}>
              {row.business}
              </TableCell>
              <TableCell className={classes.font} >{row.discount}</TableCell>
              <TableCell className={classes.font}>{row.address}</TableCell>             
            </TableRow>)
        })}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>
    </Grid>
    </Layout>
  )}
